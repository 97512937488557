import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const ListingStore = set => ({
	data: null,
	status: "",
	getSearchListing: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchListing"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetListingSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getListing: payload => {
		let data = payload?.data || payload,
			dd = {};
		if (payload?.adder) {
			dd = {
				[payload.adder]: data,
			};
		} else {
			dd = { data };
		}
		set(dd, false, "getListing");
	},
	getAllListing: payload => {
		set({ allData: payload?.data || payload }, false, "getAllListing");
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutListing: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useListingStore = create(
	devtools(
		persist(ListingStore, {
			name: "listing",
		})
	)
);

export default useListingStore;
