import React from "react";
import { ClipLoader } from "react-spinners";

const PrimaryBtn = ({
	text,
	bg,
	color,
	onClick,
	width,
	disabled,
	height,
	...restProps
}) => {
	return (
		<div>
			<button
				disabled={disabled || restProps?.loading}
				onClick={onClick}
				style={{
					background: bg,
					color: color,
				}}
				className={`${height || "tw-h-10"} ${width || "tw-min-w-24"} ${
					(disabled || restProps?.loading) &&
					"tw-cursor-not-allowed tw-bg-opacity-40"
				} tw-px-5 tw-rounded-lg tw-text-base satoshi tw-font-medium`}>
				{text}{" "}
				{restProps?.loading && (
					<ClipLoader color={restProps?.loadCss || "white"} size={16} />
				)}
			</button>
		</div>
	);
};

export const TabBtn = ({ bg, color, text, onClick, css }) => {
  return (
    <div>
      <button
        onClick={onClick}
        style={{
          background: bg,
          color: color,
        }}
        className={`tw-h-10 tw-px-5 tw-rounded-lg tw-text-base satoshi tw-font-medium ${css}`}
      >
        {text}
      </button>
    </div>
  );
};

export default PrimaryBtn;
