import React, { useContext, useEffect, useState } from "react";
import Card from "../../assets/card.svg";
import Momo from "../../assets/momo.png";
import Stripe from "../../assets/stripe.svg";
import Mtn from "../../assets/mtn.png";
import { useLocation, useNavigate } from "react-router-dom";
import { usePaystackPayment } from "react-paystack";
import moment from "moment";
import { toast } from "react-toastify";
import Header from "../../components/header";
import { ContactSection, Footer } from "../home";
import { GlobalState } from "../../data/Context";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import useErrorStore from "../../data/Stores/errorstore";
import ModalContainer from "../../components/modals/modalcontainer";
import { TabBtn } from "../../components/buttons";

const Payment = () => {
	let { state } = useLocation(),
		{ isAuth } = useAuthStore(),
		navigate = useNavigate();

	useEffect(() => {
		if (!state?.amount) {
			navigate(-1);
		}
	}, [state, navigate]);

	useEffect(() => {
		if (!state || !isAuth) navigate("/login");
	}, [state, navigate, isAuth]);

	return (
		<div>
			<HeroSection state={state} />
			<MainSection />
			<ContactSection />
			<Footer />
		</div>
	);
};

const HeroSection = ({ state }) => {
	return (
		<section className="tw-w-full tw-bg-white lg:tw-h-screen tw-h-[70vh] leaderboardbg">
			<Header />
			<div className="container tw-relative tw-h-full">
				<div className="tw-absolute tw-rounded-3xl tw-bg-[#0C1F2E] lg:tw-h-40 tw-py-5 tw-px-6 tw-flex tw-justify-center tw-items-center tw-left-0 tw-bottom-8">
					<div>
						<h1 className="tw-text-white satoshi lg:tw-text-6xl tw-text-2xl tw-font-medium tw-capitalize">
							{state?.item?.title} Plan
						</h1>
					</div>
				</div>
			</div>
		</section>
	);
};

const MainSection = () => {
	const { country } = useContext(GlobalState);
	let { state } = useLocation();

	let mainArr = [
		state?.paymentType !== "not-airtime" && {
			title: "Pay With Airtime (MTN user Only)",
			icon: Mtn,
			platform: "mtn",
		},
		{
			title: "Pay With MOMO (MTN user only)",
			icon: Momo,
			platform: "momo",
		},
		{
			title: "Pay With Credit/Debit Card",
			icon: Card,
			platform: "paystack",
		},
	];

	const MainArr = country
			? country?.toLowerCase() === "nigeria"
				? mainArr
				: [
						{
							title: "Pay With Stripe",
							icon: Stripe,
							platform: "stripe",
						},
				  ]
			: mainArr,
		[platform, setPlatform] = useState("");

	useEffect(() => {
		if (platform) {
			if (platform === "mtn") {
				let link =
					"http://ng-app.com/Martad/real-estate-affairs-landing-en-doi-web?origin_banner=1&trxId=Martad03102024&trfsrc=Realestate20241003";
				if (state?.item?.plan_id === "weekly")
					link =
						"http://ng-app.com/Martad/real-estate-affairs-landing-en-doi-web?origin_banner=2&trxId=Realestate20241003&trfsrc=Martad03102024";
				if (state?.item?.plan_id === "monthly")
					link =
						"http://ng-app.com/Martad/real-estate-affairs-landing-en-doi-web?origin_banner=3&trxId=Realestate20240310&trfsrc=Martad20240310";

				window.open(link, "_blank");
				setPlatform("");
			}
		}
	}, [platform, state]);

	return (
		<div>
			<div className="tw-w-full tw-min-h-screen tw-py-16 gamebg">
				<div className="tw-p-12 tw-rounded-xl category-bg container">
					<div className="lg:tw-w-5/6 tw-mx-auto">
						<div className="tw-w-full tw-rounded-2xl tw-mt-10 tw-bg-[#0C1F2E] tw-p-10">
							<h2 className="tw-text-3xl tw-font-bold nexa tw-text-white tw-text-center">
								Payment Methods
							</h2>
							<div className="tw-w-5/6 tw-mt-10 tw-mx-auto tw-space-y-4">
								{MainArr?.filter(e => e)?.map(it => (
									<div className="tw-w-full tw-bg-[#D9C880] lg:tw-h-24 tw-py-4 lg:tw-py-0 tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center tw-justify-between tw-px-5 tw-rounded-xl">
										<div className="tw-flex tw-gap-3 tw-items-center">
											<img src={it?.icon} alt="" className="tw-h-10" />
											<strong className="lg:tw-text-xl tw-text-sm tw-text-center lg:tw-text-left tw-font-bold nexa tw-text-plain">
												{it?.title}
											</strong>
										</div>
										<div className="tw-mt-4 tw-flex lg:tw-block lg:tw-justify-normal tw-justify-center lg:tw-mt-0">
											<TabBtn
												text={"Pay Now"}
												onClick={() => setPlatform(it?.platform)}
												bg={"#1C466C"}
												color={"white"}
											/>
										</div>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			{["momo", "stripe"]?.includes(platform) && (
				<ComingSoon handleClose={() => setPlatform("")} />
			)}
			{platform === "paystack" && (
				<MakeCardsPaystack handleClose={() => setPlatform("")} />
			)}
		</div>
	);
};

export default Payment;

export const MakeCardsPaystack = ({ handleClose }) => {
	let { user } = useAuthStore(),
		{ state } = useLocation(),
		[reference, setReference] = useState(""),
		navigate = useNavigate(),
		{ returnErrors } = useErrorStore(),
		// { msisdn } = useContext(GlobalState),
		config = {
			email: user?.email,
			amount: Number(state?.amount * 100),
			publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
			metadata: {
				name: `${user?.firstName || ""} ${user?.lastName || ""}`,
				phone: user?.telephone || "",
			},
			reference: reference ? reference?.toString()?.split("|")?.join("") : "",
		},
		initializePayment = usePaystackPayment(config);
	console.log({ config });
	useEffect(() => {
		if (state?.amount) {
			setReference(moment().format("YYYYMMDDHHmmssASSS"));
		}
	}, [state?.amount]);

	let handleSuccess = async item => {
		setLoading(true);
		console.log({ item }, "async");
		setLoading(true);

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/subscription`,
			{
				reference: item?.reference,
				channel: "paystack",
				planId: state?.item?.planId,
			}
		);
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/");
			handleClose();
			return;
		}
		setLoading(false);
	};

	// you can call this function anything
	const onClose = () => {
		// implementation for  whatever you want to do when the Paystack dialog closed.
		console.log("closed");
		handleClose();
	};

	const onSuccess = ref => {
		console.log({ ref }, "success");
		handleSuccess(ref);
	};

	useEffect(() => {
		if (reference) {
			initializePayment({ onSuccess, onClose });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reference]);

	let [loading, setLoading] = useState(false);

	return (
		<>
			<ModalContainer handleClose={handleClose}>
				<div
					style={{
						overflowY: "hidden",
					}}
					className="lg:tw-w-full  tw-max-h-[550px] tw-rounded-xl twpb-6 category-bg">
					<div className="tw-flex tw-align-center tw-justify-center">
						<TabBtn
							text={"Paystack"}
							loading={loading}
							onClick={() => {}}
							bg={"#1C466C"}
							color={"white"}
						/>
					</div>
				</div>
			</ModalContainer>
		</>
	);
};

export const ComingSoon = ({ handleClose }) => {
	return (
		<ModalContainer handleClose={handleClose}>
			<div
				style={{
					overflowY: "hidden",
				}}
				className="lg:tw-w-full  tw-max-h-[550px] tw-rounded-xl twpb-6 category-bg">
				<h1
					data-aos="fade-up"
					data-aos-duration="1500"
					className="tw-text-4xl  sansation tw-font-extrabold text-center tw-leading-10 tw-text-[#1C466C]">
					Coming Soon!!!
				</h1>
			</div>
		</ModalContainer>
	);
};

// export const ParentComponent = ({ handleClose }) => {
// 	let location = useLocation(),
// 		[secret, setSecret] = useState(""),
// 		[secretErr, setSecretErr] = useState("");

// 	const stripePromise = loadStripe(process.env.REACT_APP_STIPE_PUBLISH_KEY);

// 	useEffect(() => {
// 		if (location?.state?.amount) {
// 			let getSecret = async () => {
// 				let stat = {
// 					amount: location?.state?.amount,
// 				};
// 				try {
// 					var res = await axios.put("/api/v1/payment/booking", {
// 						...stat,
// 					});
// 					console.log({ res });
// 					setSecret(res?.data?.data, { autoClose: 5000 });
// 				} catch (err) {
// 					let thisErr = err?.message;

// 					if (err) console.log({ error: err.response?.data, err });
// 					if (err?.response?.status === 429) toast.error(err?.response?.data);
// 					let error = err.response?.data?.error;
// 					if (error) {
// 						thisErr = error?.toString();
// 					} else {
// 						thisErr = err?.response?.data?.message;
// 					}
// 					// navigate(-1);
// 					setSecretErr(thisErr);
// 				}
// 			};
// 			getSecret();
// 		}
// 		// eslint-disable-next-line react-hooks/exhaustive-deps
// 	}, [location?.state]);

// 	return (
// 		<>
// 			{/* {secret && stripePromise ? (
// 				<Elements stripe={stripePromise} options={{ clientSecret: secret }}>
// 					<StripePayment
// 						secret={secret}
// 						secretErr={secretErr}
// 						handleClose={handleClose}
// 					/>
// 				</Elements>
// 			) : (
// 				<>
// 					<StripePayment
// 						secret={secret}
// 						secretErr={secretErr}
// 						handleClose={handleClose}
// 					/>
// 				</>
// 			)} */}
// 		</>
// 	);
// };
