import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { NewTextinput } from "./contact";
import Logo from "../components/logo";
import useAuthStore, { apiCall } from "../data/Stores/AuthStore";
import useErrorStore from "../data/Stores/errorstore";
import useGenFetcher from "../data/useGenFetcher";
import { toast } from "react-toastify";
import { QuizBtn } from "./trivia";
import useSettingStore from "../data/Stores/settingsstore";

const Register = () => {
	const navigate = useNavigate();
	const [show, setShow] = useState();
	const { login } = useAuthStore(),
		{ loadUser } = useGenFetcher(),
		{ data } = useSettingStore();

	const init = {},
		[state, setState] = useState(init),
		textChange = e => {
			let { name, value } = e.target;
			setState({ ...state, [name]: value });
		},
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.lastName) errArr2?.push("Last Name is required");
		if (!state?.firstName) errArr2?.push("First Name is required");
		if (!state?.telephone) errArr2?.push("Telephone is required");
		if (!state?.email) errArr2?.push("Email is required");
		if (!state?.password) errArr2?.push("Password is required");
		if (state?.password && state?.password !== state?.confirmPassword)
			errArr2?.push("Password do not match");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		let { response, errArr, errMsg } = await apiCall(
			"post",
			`/api/v1/user`,
			newState
		);
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			login(response);
			loadUser();
			navigate("/");
			return;
		}
		setLoading(false);
	};

	return (
		<div>
			<div className="tw-h-screen tw-flex tw-flex-col lg:tw-flex-row tw-w-full tw-bg-white">
				<div className="lg:tw-min-h-full lg:tw-w-[60%] loginbg tw-bg-[#0A1C2D99] tw-flex tw-items-center lg:tw-px-16 tw-px-4 tw-py-7 tw-relative">
					<div>
						<div className="lg:tw-absolute tw-top-8 tw-left-8">
							<Logo />
						</div>
						<div>
							<h3 className="lg:tw-text-4xl tw-text-xl tw-font-extrabold satoshi tw-text-white">
								{data?.registerTitle || `Lorem ipsum dolor sit ame`}
							</h3>
							<p className="lg:tw-text-xl tw-text-sm tw-mt-5 tw-font-normal tw-text-white satoshi">
								{data?.registerSubTitle ||
									`Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
								eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
								enim ad minim veniam, quis nostrud exercitation ullamco laboris
								nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
								in reprehenderit in voluptate velit esse cillum dolor`}
							</p>
						</div>
					</div>
				</div>
				<form className="tw-p-10 lg:tw-w-[40%] tw-relative tw-min-h-full">
					<h2 className="tw-text-xm tw-text-main tw-font-extrabold satoshi">
						Sign Up
					</h2>
					<small className="tw-text-main tw-font-normal tw-text-sm satoshi">
						Welcome here
					</small>
					<div className="tw-mt-16 tw-w-full tw-space-y-4">
						<NewTextinput
							label={"First name"}
							value={state?.firstName}
							onChange={textChange}
							name="firstName"
						/>
						<NewTextinput
							label={"Last name"}
							value={state?.lastName}
							onChange={textChange}
							name="lastName"
						/>
						<NewTextinput
							label={"Email"}
							value={state?.email}
							onChange={textChange}
							name="email"
							type="email"
						/>
						<NewTextinput
							label={"Phone number"}
							value={state?.telephone}
							onChange={textChange}
							name="telephone"
							type={"tel"}
							maxLength={11}
						/>
						<div>
							<div>
								<small className="tw-text-base tw-font-normal satoshi tw-text-[#272424]">
									Password
								</small>
							</div>
							<div className="tw-w-full tw-relative tw-h-14">
								<input
									type={show ? "text" : "password"}
									className="tw-w-full tw-h-full tw-pl-5 tw-rounded-lg tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-black tw-border-[0.5px] tw-border-[#FFFFFF33]"
									value={state?.password}
									onChange={textChange}
									name="password"
								/>
								<div
									onClick={() => setShow(!show)}
									className="tw-absolute tw-cursor-pointer tw-right-4 tw-top-5">
									{show ? <FaEyeSlash /> : <FaEye />}
								</div>
							</div>
						</div>
						<div>
							<div>
								<small className="tw-text-base tw-font-normal satoshi tw-text-[#272424]">
									Confirm Password
								</small>
							</div>
							<div className="tw-w-full tw-relative tw-h-14">
								<input
									type={show ? "text" : "password"}
									className="tw-w-full tw-h-full tw-pl-5 tw-rounded-lg tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-black tw-border-[0.5px] tw-border-[#FFFFFF33]"
									value={state?.confirmPassword}
									onChange={textChange}
									name="confirmPassword"
								/>
								<div
									onClick={() => setShow(!show)}
									className="tw-absolute tw-cursor-pointer tw-right-4 tw-top-5">
									{show ? <FaEyeSlash /> : <FaEye />}
								</div>
							</div>
						</div>
					</div>
					<div className="tw-mt-20 tw-px-10">
						{/* <button
							onClick={handleSubmit}
							className="tw-h-14 tw-w-full tw-bg-[#005BAD] tw-rounded-xl tw-text-white tw-font-bold tw-text-base satoshi">
							Sign Up
						</button> */}
						<QuizBtn
							onClick={handleSubmit}
							text={"Sign Up"}
							css="tw-h-14 tw-w-full tw-bg-[#005BAD] tw-rounded-xl tw-text-white tw-font-bold tw-text-base satoshi"
							loading={loading}
						/>
						<h6 className="tw-text-sm tw-text-center tw-pt-2 tw-font-medium tw-text-main satoshi">
							Already have an account?{" "}
							<span
								onClick={() => navigate("/login")}
								className="tw-font-bold tw-cursor-pointer">
								Log In
							</span>
						</h6>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Register;
