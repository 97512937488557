import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { ContactSection, Footer } from "../home";
// import Prop1 from "../../assets/properties1.png";
// import Prop2 from "../../assets/properties2.png";
import PrimaryBtn from "../../components/buttons";
import { motion } from "framer-motion";
import { createMarkup } from "../pricing";
import { useLocation } from "react-router-dom";
import useCategoryStore from "../../data/Stores/categorystore";
import useListingStore from "../../data/Stores/listingStore";
import { apiCall } from "../../data/Stores/AuthStore";
import { LoadMore, MainPaginate } from "../../components/Pagination";

const Properties = () => {
  return (
    <div>
      <motion.div exit={{ opacity: 0 }} duration={0.5}>
        <MainSection />
        <ContactSection />
        <Footer />
      </motion.div>
    </div>
  );
};

const MainSection = () => {
  const // Arr = [
    // 	{
    // 		img: Prop1,
    // 		name: "House 001",
    // 		desc: "Serene Retreat Residence",
    // 		features: [
    // 			{ main: "1 bedroom", sub: "1 bathroom" },
    // 			{ main: "1 parking", sub: "1 kitchen" },
    // 		],
    // 	},
    // 	{
    // 		img: Prop2,
    // 		name: "House 001",
    // 		desc: "Serene Retreat Residence",
    // 		features: [
    // 			{ main: "1 bedroom", sub: "1 bathroom" },
    // 			{ main: "1 parking", sub: "1 kitchen" },
    // 		],
    // 	},
    // 	{
    // 		img: Prop1,
    // 		name: "House 001",
    // 		desc: "Serene Retreat Residence",
    // 		features: [
    // 			{ main: "1 bedroom", sub: "1 bathroom" },
    // 			{ main: "1 parking", sub: "1 kitchen" },
    // 		],
    // 	},
    // 	{
    // 		img: Prop2,
    // 		name: "House 001",
    // 		desc: "Serene Retreat Residence",
    // 		features: [
    // 			{ main: "1 bedroom", sub: "1 bathroom" },
    // 			{ main: "1 parking", sub: "1 kitchen" },
    // 		],
    // 	},
    // 	{
    // 		img: Prop1,
    // 		name: "House 001",
    // 		desc: "Serene Retreat Residence",
    // 		features: [
    // 			{ main: "1 bedroom", sub: "1 bathroom" },
    // 			{ main: "1 parking", sub: "1 kitchen" },
    // 		],
    // 	},
    // 	{
    // 		img: Prop2,
    // 		name: "House 001",
    // 		desc: "Serene Retreat Residence",
    // 		features: [
    // 			{ main: "1 bedroom", sub: "1 bathroom" },
    // 			{ main: "1 parking", sub: "1 kitchen" },
    // 		],
    // 	},
    // ],
    // navigate = useNavigate(),
    { state } = useLocation();

  // useEffect(() => {
  // 	if (!state) navigate(-1);
  // }, [state, navigate]);

  let category = useCategoryStore(),
    [loading, setLoading] = useState(null),
    [datum, setDatum] = useState(null),
    listing = useListingStore();

  useEffect(() => {
    if (state) {
      let getFunc = async () => {
        let { response } = await apiCall(
          "get",
          `/api/v1/listing${
            state && state !== "all" ? `?category=${state}` : ""
          }`
        );
        listing?.getListing(
          state && state !== "all"
            ? {
                ...response,
                adder: category?.data?.docs?.find((it) => it?._id === state)
                  ?.title,
              }
            : response
        );
      };
      getFunc();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state !== "all") {
      let finder = category?.data?.docs?.find((it) => it?._id === state);
      if (finder && listing?.[finder?.title]) {
        setDatum(listing?.[finder?.title]);
      } else {
        setDatum(null);
      }
    } else {
      setDatum(listing?.data);
    }
  }, [category?.data?.docs, listing, state]);

  const [range] = useState(10),
    [page, setPage] = useState(1),
    [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + range;

  // if (!datum && !data) return <PageLoader />;
  if (!datum) return;

  const currentItems = datum?.docs?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(datum?.docs?.length / range);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * range) % datum?.docs?.length;
    setItemOffset(newOffset);
    setPage(1 + event?.selected);
  };

  let handleLoadMore = async () => {
    setLoading("loadmore");

    let { response } = await apiCall(
      "get",
      `/api/v1/listing?limit=${datum?.limit * datum?.nextPage}${
        state && state !== "all" ? `&category=${state}` : ""
      }`
    );
    listing?.getListing(
      state && state !== "all"
        ? {
            ...response,
            adder: category?.data?.docs?.find((it) => it?._id === state)?.title,
          }
        : response
    );
    setLoading(false);
  };

  return (
    <section className="tw-w-full tw-bg-white tw-relative tw-py-40">
      <Header textColor={"#06094F"} btnBg={"#06094F"} btnColor={"white"} />
      <div className="container tw-grid md:tw-grid-cols-2 tw-gap-10">
        {currentItems?.map((it, i) => (
          <div key={i} className="tw-relative tw-h-96 tw-w-full">
            {it?.image?.url && (
              <img
                src={it?.image?.url}
                alt=""
                className="tw-w-full tw-h-full tw-rounded-xl"
              />
            )}
            <div className="tw-absolute tw-left-6 tw-bottom-5 tw-p-6 tw-bg-[#FFFFFFE5] tw-w-64 tw-rounded-xl">
              <h3 className="tw-text-2xl bolder tw-text-scblue">{it?.title}</h3>
              <strong
                className="tw-text-base truncate
				 tw-font-bold tw-truncate tw-w-full satoshi tw-text-scblue"
                dangerouslySetInnerHTML={createMarkup(it?.content)}
              />
              <div className="tw-space-y-3 tw-mt-4 tw-w-full">
                <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
                  <div>
                    <small className="smallText tw-capitalize">
                      {it?.noOfBedroom ? <>{it?.noOfBedroom} Bedroom</> : null}
                    </small>
                  </div>
                  <div>
                    <small className="smallText tw-capitalize">
                      {it?.noOfBathRoom ? (
                        <>{it?.noOfBathRoom} Bathroom</>
                      ) : null}
                    </small>
                  </div>
                </div>
                <div className="tw-w-full tw-flex tw-justify-between tw-items-center">
                  <div>
                    <small className="smallText tw-capitalize">
                      {it?.noOfParking ? <>{it?.noOfParking} Parking</> : null}
                    </small>
                  </div>
                  <div>
                    <small className="smallText tw-capitalize">
                      {it?.noOfKitchen ? <>{it?.noOfKitchen} Kitchen</> : null}
                    </small>
                  </div>
                </div>
              </div>
              <div className="tw-mt-5">
                <PrimaryBtn
                  bg={"#1C466C"}
                  text={
                    category?.data?.docs?.find((it) => it?._id === state)
                      ?.homePageTitle || "More"
                  }
                  onClick={() => {
                    apiCall("patch", `/api/v1/listing?listing=${it?._id}`);
                    window.open(it?.linkedURL, "_blank");
                  }}
                  color={"white"}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="tw-mt-4 tw-flex tw-justify-center">
        <LoadMore
          next={page === pageCount && datum?.hasNextPage}
          loading={loading === "loadmore"}
          handleLoadMore={handleLoadMore}
        />
      </div>
      {pageCount > 1 && (
        <MainPaginate pageCount={pageCount} handlePageClick={handlePageClick} />
      )}
    </section>
  );
};

export default Properties;
