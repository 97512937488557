import React, { useState } from "react";
import Logo from "./logo";
import { useNavigate } from "react-router-dom";
import PrimaryBtn from "./buttons";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { motion } from "framer-motion";
import useAuthStore from "../data/Stores/AuthStore";

const Header = ({ textColor, btnBg, btnColor }) => {
	const navigate = useNavigate(),
		[nav, setNav] = useState(false),
		{ isAuth, logout } = useAuthStore(),
		links = [
			{ name: "Home", url: "/", show: true },
			{ name: "properties", url: "/properties", show: true },
			{ name: "pages", url: "/pages", show: false },
			{ name: "leaderboard", url: "/leaderboard", show: false },
			{ name: "trivia", url: "/trivia", show: false },
			{ name: "contact us", url: "/contact", show: true },
			{ name: "about us", url: "/", show: true },
		];
	return (
		<div>
			<div className="tw-w-full tw-h-16 tw-absolute tw-z-50 lg:tw-top-8 tw-top-4 tw-border-[0.5px] tw-border-white tw-bg-[#FFFFFF1A]">
				<div className="container tw-flex tw-justify-between tw-h-full tw-items-center">
					<Logo />
					<div className="lg:tw-flex tw-gap-6 tw-items-center tw-hidden">
						{links
							?.filter(it => it?.show)
							?.map((it, i) => (
								<div
									key={i}
									onClick={() =>
										navigate(it?.url, {
											state: it?.url === "/properties" ? "all" : "",
										})
									}
									className="tw-cursor-pointer">
									<strong
										style={{
											color: textColor || "white",
										}}
										className="tw-text-sm tw-capitalize tw-cursor-pointer tw-font-medium satoshi">
										{it?.name}
									</strong>
								</div>
							))}
					</div>
					{/* <div className="tw-block lg:tw-hidden"> */}
					<div className="tw-hidden">
						{nav ? (
							<IoClose
								onClick={() => setNav(false)}
								color={btnBg || "white"}
								className="tw-cursor-pointer"
							/>
						) : (
							<GiHamburgerMenu
								onClick={() => setNav(true)}
								className="tw-cursor-pointer"
								color={btnBg || "white"}
								size={20}
							/>
						)}
					</div>
					{nav && <MobileNav links={links} navigate={navigate} />}
					{/* <div className="lg:tw-flex tw-hidden tw-gap-3 tw-items-center"> */}
					<div className="tw-hidden tw-gap-3 tw-items-center">
						{isAuth ? (
							<>
								<PrimaryBtn
									onClick={() => {
										logout();
										navigate("/");
									}}
									text={"Logout"}
									bg={btnBg || "white"}
									color={btnColor || "#06094F"}
								/>
							</>
						) : (
							<>
								<div>
									<strong
										onClick={() => navigate("/register")}
										style={{
											color: textColor || "white",
										}}
										className="tw-text-base tw-capitalize tw-cursor-pointer tw-font-medium  satoshi">
										Sign up
									</strong>
								</div>
								<PrimaryBtn
									onClick={() => navigate("/login")}
									text={"Login"}
									bg={btnBg || "white"}
									color={btnColor || "#06094F"}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

const MobileNav = ({ links, navigate }) => {
	let { isAuth, logout } = useAuthStore();

	return (
		<motion.div
			className="tw-absolute tw-shadow-md tw-rounded-xl tw-z-50 tw-w-3/4 tw-right-0 tw-top-16 tw-p-6 tw-bg-white"
			style={{
				maxWidth: "550px",
			}}
			initial={{
				x: 250,
			}}
			animate={{
				x: 0,
			}}
			transition={{
				duration: 0.3,
				delay: 0.5,
				type: "tween",
			}}>
			<div className="">
				<div className="tw-space-y-2">
					{links
						?.filter(it => it?.show)
						?.map((it, i) => (
							<div
								key={i}
								onClick={() => navigate(it?.url)}
								className="tw-cursor-pointer">
								<strong className="tw-text-sm tw-text-scblue tw-capitalize tw-cursor-pointer tw-font-medium satoshi">
									{it?.name}
								</strong>
							</div>
						))}
				</div>
				<div className="tw-flex tw-gap-3 tw-mt-5 tw-items-center">
					{isAuth ? (
						<>
							<PrimaryBtn text={"Logout"} bg={"#0C1F2E"} color={"white"} />
							<PrimaryBtn
								onClick={() => {
									logout();
									navigate("/");
								}}
								text={"Logout"}
								bg={"white"}
								color={"#06094F"}
							/>
						</>
					) : (
						<>
							<div>
								<strong
									onClick={() => navigate("/register")}
									className="tw-text-base tw-capitalize tw-text-scblue tw-cursor-pointer tw-font-medium  satoshi">
									Sign up
								</strong>
							</div>
							<PrimaryBtn
								onClick={() => navigate("/login")}
								text={"Login"}
								bg={"#0C1F2E"}
								color={"white"}
							/>
						</>
					)}
				</div>
			</div>
		</motion.div>
	);
};

export default Header;
