import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const CategoryStore = set => ({
	data: null,
	status: "",
	getCategory: payload => {
		set({ data: payload?.data || payload }, false, "getCategory");
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutCategory: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useCategoryStore = create(
	devtools(
		persist(CategoryStore, {
			name: "category",
		})
	)
);

export default useCategoryStore;
