import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { ContactSection, Footer } from "../home";
import { motion } from "framer-motion";
import useSettingStore from "../../data/Stores/settingsstore";
import { apiCall } from "../../data/Stores/AuthStore";
import { createMarkup } from "../pricing";


export const MainTerm = ({ title, section }) => {
	return (
		<div>
			<motion.div exit={{ opacity: 0 }} duration={0.5}>
				<HeroSection title={title} />
				<MainSection section={section} />
				<ContactSection />
				<Footer />
			</motion.div>
		</div>
	);
};

const Term = () => <MainTerm />;

const HeroSection = ({ title }) => {
	return (
		<section className="tw-w-full tw-bg-white lg:tw-h-screen tw-h-[70vh] termbg">
			<Header textColor={"#06094F"} btnBg={"#06094F"} btnColor={"white"} />
			<div className="container tw-relative tw-h-full">
				<div className="tw-absolute tw-rounded-3xl tw-bg-[#0C1F2E] lg:tw-h-40 tw-py-5 tw-px-6 tw-flex tw-justify-center tw-items-center tw-left-0 tw-bottom-8">
					<div>
						<h1 className="tw-text-white satoshi lg:tw-text-6xl tw-text-2xl tw-font-medium">
							{title || `Terms & Condition`}
						</h1>
					</div>
				</div>
			</div>
		</section>
	);
};

const MainSection = ({ section }) => {
	let [type, setType] = useState("");

	return (
		<section className="tw-w-full tw-py-20 tw-bg-white">
			<div className="container">
				<div className="tw-space-y-6">
					<PurifiedText
						type={type}
						setType={setType}
						section={section || "termsAndConditions"}
					/>
					{!type && (
						<>
							<div>
								{" "}
								<h2 className="tw-text-2xl tw-font-bold tw-text-black satoshi">
									At vero eos et accusamus et iu
								</h2>
								<p className="tw-text-base tw-font-normal tw-text-black satoshi">
									"At vero eos et accusamus et iusto odio dignissimos ducimus
									qui blanditiis praesentium voluptatum deleniti atque corrupti
									quos dolores et quas molestias excepturi sint occaecati
									cupiditate non provident, similique sunt in culpa qui officia
									deserunt mollitia animi, id est laborum et dolorum fuga. Et
									harum quidem rerum facilis est et expedita disti"At vero eos
									et accusamus et iusto odio dignissimos ducimus qui blanditiis
									praesentium voluptatum deleniti atque corrupti quos dolores et
									quas molestias excepturi sint occaecati cupiditate non
									provident, similique sunt in culpa qui officia deserunt
									mollitia animi, id est laborum et dolorum fuga. Et harum
									quidem rerum facilis est et expedita disti"At vero eos et
									accusamus et iusto odio dignissimos ducimus qui blanditiis
									praesentium voluptatum deleniti atque corrupti quos dolores et
									quas molestias excepturi sint occaecati cupiditate non
									provident, similique sunt in culpa qui officia deserunt
									mollitia animi, id est laborum et dolorum fuga. Et harum
									quidem rerum facilis est et expedita disti
								</p>
							</div>
							<div>
								{" "}
								<h2 className="tw-text-2xl tw-font-bold tw-text-black satoshi">
									At vero eos et accusamus et iu
								</h2>
								<p className="tw-text-base tw-font-normal tw-text-black satoshi">
									"At vero eos et accusamus et iusto odio dignissimos ducimus
									qui blanditiis praesentium voluptatum deleniti atque corrupti
									quos dolores et quas molestias excepturi sint occaecati
									cupiditate non provident, similique sunt in culpa qui officia
									deserunt mollitia animi, id est laborum et dolorum fuga. Et
									harum quidem rerum facilis est et expedita disti"At vero eos
									et accusamus et iusto odio dignissimos ducimus qui blanditiis
									praesentium voluptatum deleniti atque corrupti quos dolores et
									quas molestias excepturi sint occaecati cupiditate non
									provident, similique sunt in culpa qui officia deserunt
									mollitia animi, id est laborum et dolorum fuga. Et harum
									quidem rerum facilis est et expedita disti"At vero eos et
									accusamus et iusto odio dignissimos ducimus qui blanditiis
									praesentium voluptatum deleniti atque corrupti quos dolores et
									quas molestias excepturi sint occaecati cupiditate non
									provident, similique sunt in culpa qui officia deserunt
									mollitia animi, id est laborum et dolorum fuga. Et harum
									quidem rerum facilis est et expedita disti
								</p>
							</div>
						</>
					)}
				</div>
			</div>
		</section>
	);
};

export const PurifiedText = ({ type, setType, section }) => {
  let { data, getSetting } = useSettingStore();

  useEffect(() => {
    apiCall("get", "/api/v1/settings", null, getSetting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      let datum = data;
      if (datum?.[section]) {
        setType(data?.[section]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      {type && (
        <>
          <p
            className="tw-text-base tw-font-normal tw-text-black satoshi"
            dangerouslySetInnerHTML={createMarkup(type)}
          />
        </>
      )}
    </>
  );
};

export default Term;
