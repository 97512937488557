import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";

const MainHome = () => {
  let navigate = useNavigate();
  let { auth } = useAuthStore();
  useEffect(() => {
    if (auth) {
      navigate("/dashboard");
    }
  }, [navigate, auth]);
  return <div></div>;
};

export default MainHome;
