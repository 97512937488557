import React, { useState, useEffect, useCallback } from "react";
import Header from "../../components/header";
import Trophy from "../../assets/trophy.svg";
import { ContactSection, Footer } from "../home";
import { motion } from "framer-motion";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import { useNavigate } from "react-router-dom";
import useErrorStore from "../../data/Stores/errorstore";
import { toast } from "react-toastify";
import { createMarkup } from "../pricing";
import { ClipLoader } from "react-spinners";
import useSettingStore from "../../data/Stores/settingsstore";
import { LoadingLoader } from "../../components/modals/modalcontainer";
import { TabBtn } from "../../components/buttons";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

const Trivia = () => {
  let navigate = useNavigate(),
    { isAuth } = useAuthStore();

  useEffect(() => {
    if (!isAuth) navigate("/login");
  }, [isAuth, navigate]);

  return (
    <div>
      <motion.div exit={{ opacity: 0 }} duration={0.5}>
        <HeroSection />
        <ContactSection />
        <Footer />
      </motion.div>
    </div>
  );
};

const HeroSection = () => {
  const [isQuestion, setIsQuestion] = useState(0),
    [isSelected, setIsSelected] = useState(null),
    [triviaQues, setTriviaQues] = useState(null),
    [loading, setLoading] = useState("loader"),
    [answerArr, setAnswerArr] = useState([]),
    { returnErrors } = useErrorStore(),
    { data, getSetting } = useSettingStore(),
    navigate = useNavigate(),
    [result, setResult] = useState(null);

  // const QuestionsArr = [
  // 	{
  // 		question:
  // 			"On proident, sunt in culpa qui officia deserunt mollit anim id est laborum.?",
  // 		options: ["true", "false", "Yes", "No"],
  // 		isCorrect: "true",
  // 	},
  // 	{
  // 		question:
  // 			"On proident, sunt in culpa qui officia deserunt mollit anim id est laborum.?",
  // 		options: ["true", "false"],
  // 		isCorrect: "true",
  // 	},
  // 	{
  // 		question:
  // 			"On proident, sunt in culpa qui officia deserunt mollit anim id est laborum.?",
  // 		options: ["true", "false"],
  // 		isCorrect: "true",
  // 	},
  // ];
  const currentQuestion = triviaQues?.docs?.[isQuestion];

  const handleNext = useCallback(() => {
    if (isSelected) {
      let d = triviaQues?.docs?.[isQuestion];
      let findQue = [
        ...answerArr?.map((item) =>
          item?.questionID === d?._id
            ? {
                ...item,
                option: isSelected?.answer,
              }
            : item
        ),
      ];
      setAnswerArr(findQue);
      if (isQuestion !== triviaQues?.docs?.length - 1) {
        setIsSelected(null);
        setIsQuestion(isQuestion + 1);
      } else handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, isQuestion, triviaQues?.docs, answerArr]);

  const handlePrev = () => {
    if (isQuestion > 0) {
      setIsQuestion(isQuestion - 1);
      setIsSelected(null);
    }
  };

  let handleSubmit = async (e) => {
    // console.log({ query });
    let d = triviaQues?.docs?.[isQuestion];
    let findQue = [
      ...answerArr?.map((item) =>
        item?.questionID === d?._id
          ? {
              ...item,
              option: isSelected?.answer,
            }
          : item
      ),
    ];
    // console.log({ findQue, d });
    setLoading(true);
    let dd = {
      questions: findQue,
    };
    let { response, errMsg, errArr } = await apiCall(
      "post",
      "/api/v1/question/submit",
      { ...dd }
    );
    if (errArr) {
      setLoading(false);
      return returnErrors(errArr);
    }
    if (errMsg) {
      setLoading(false);
      return toast.error(errMsg);
    }
    if (response) {
      setLoading(false);
      setIsQuestion();
      setIsQuestion(isQuestion + 1);
      setIsSelected(null);
      setResult(response?.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    let getQues = async () => {
      let { response, errMsg, errArr } = await apiCall(
        "get",
        "/api/v1/question",
        null,
        null,
        null,
        "noToast"
      );

      // console.log({ response, errMsg, errArr });

      if (errArr) {
        setLoading(false);
        navigate("/");
        return returnErrors(errArr);
      }
      if (errMsg) {
        setLoading(false);
        navigate("/");
        return toast.error(errMsg);
      }
      if (response) {
        setLoading(false);
        if (response?.data?.docs?.length === 0) {
          toast.info("No questions found");
          return navigate("/");
        }
        setTriviaQues(response?.data);
        setAnswerArr(
          response?.data?.docs?.map((it) => {
            return { questionID: it?._id };
          })
        );
      }
      setLoading(false);
    };
    apiCall("get", "/api/v1/settings", null, getSetting);

    getQues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log({ triviaQues, isSelected, result });

  return (
    <section className="tw-w-full tw-bg-white tw-min-h-screen tw-py-40 triviabg">
      <Header />
      {loading === "loader" ? (
        <LoadingLoader />
      ) : currentQuestion ? (
        <div className="container tw-relative tw-min-h-full tw-py-16 tw-border-2 tw-border-white tw-rounded-tr-[56px] tw-rounded-bl-[56px]">
          <div className="lg:tw-w-3/4 tw-mx-auto tw-bg-[#FFFFFFB2] tw-py-8 tw-px-12 tw-rounded-tr-[56px] tw-rounded-bl-[56px]">
            <h4 className="tw-text-2xl tw-font-bold satoshi tw-text-center tw-text-scblue">
              {`Question ${isQuestion + 1} of ${triviaQues?.docsTotal}`}
            </h4>
            {/* <p className="tw-text-xl tw-text-center tw-max-w-xl tw-mx-auto tw-text-scblue satoshi">
							{currentQuestion?.question}
						</p> */}
            <div
              className="tw-text-xl tw-text-center tw-max-w-xl tw-mx-auto tw-text-scblue satoshi"
              dangerouslySetInnerHTML={createMarkup(currentQuestion?.question)}
            />
            <div className="tw-mt-5 tw-w-3/5 tw-mx-auto tw-grid md:tw-grid-cols-2 tw-gap-8">
              {currentQuestion?.options?.map((ans, i) => (
                <div
                  onClick={() => {
                    setIsSelected({
                      answer: i + 1,
                      option: ans?.option || ans,
                    });
                  }}
                  key={i}
                  style={{
                    background:
                      isSelected?.option === (ans?.option || ans) &&
                      isSelected?.answer - 1 === i
                        ? "#1C466C"
                        : "#ffffff",
                  }}
                  className="tw-flex tw-p-4 tw-justify-center tw-cursor-pointer tw-items-center lg:tw-w-56 tw-bg-white tw-rounded-tr-3xl tw-rounded-bl-3xl"
                >
                  {/* <h4> {(isSelected, isAnswer)}</h4> */}
                  <strong
                    className="tw-text-base tw-font-normal satoshi"
                    style={{
                      color:
                        isSelected?.option === (ans?.option || ans) &&
                        isSelected?.answer - 1 === i
                          ? "#ffffff"
                          : "#1C466C",
                    }}
                  >
                    {" "}
                    {i === 0
                      ? "A"
                      : i === 1
                      ? "B"
                      : i === 2
                      ? "C"
                      : i === 3
                      ? "D"
                      : ""}
                    :
                    <span className="tw-text-sm satoshi tw-capitalize tw-ml-3 tw-font-normal ">
                      {ans?.option || ans}
                    </span>
                  </strong>
                </div>
              ))}
            </div>
          </div>
          <div className="tw-w-5/6 tw-mx-auto tw-space-y-6 lg:tw-space-y-0 tw-mt-10 tw-flex tw-justify-between  tw-flex-col md::tw-flex-row lg:tw-items-center">
            <div className="tw-flex tw-flex-col md:tw-flex-row tw-gap-4 md:tw-mb-2">
              <QuizBtn
                text={`Question ${isQuestion + 1} Of ${triviaQues?.docsTotal}`}
              />
              <QuizBtn text={"Previous"} onClick={handlePrev} />
            </div>
            <QuizBtn
              onClick={handleNext}
              text={
                isQuestion === triviaQues?.docsTotal - 1 ? "Submit" : "Next"
              }
              loadCss="#1C466C"
            />
          </div>
        </div>
      ) : (
        <ScoreBoard result={result} settings={data} />
      )}
    </section>
  );
};

const ScoreBoard = ({ result, settings }) => {
  const Arr = [
      {
        title: "Your Score",
        score: result?.score,
        point: result?.correctAnswers * settings?.pointsPerScore,
      },
      { title: "Passing", score: settings?.passScore },
    ],
    navigate = useNavigate(),
    percent = result?.score,
    { user } = useAuthStore(),
    { width, height } = useWindowSize();

  return (
    <div className="container tw-py-8 tw-bg-white tw-rounded-3xl tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center">
      {percent && Number(percent).toFixed(0) >= 60 ? (
        <Confetti width={width} height={height} />
      ) : null}
      <div className="tw-ml-8">
        <img src={Trophy} alt="" className="" />
      </div>
      <div className="tw-mr-8">
        <h6 className="tw-text-2xl tw-font-bold satoshi tw-text-black">
          {percent && Number(percent).toFixed(0) >= 60
            ? "Congratulations"
            : "Hey,"}{" "}
          {user?.lastName}
        </h6>
        <div className="lg:tw-w-[700px] tw-rounded-2xl tw-p-8 tw-border-0.5 tw-border-black">
          <strong className="tw-text-xl tw-font-medium tw-text-black satoshi">
            Results
          </strong>
          <div className="tw-w-full tw-space-y-5">
            {Arr?.map((it, i) => (
              <div
                key={i}
                className="tw-w-full tw-grid tw-grid-cols-3 tw-gap-4"
              >
                <ScoreCard>
                  <small className="tw-text-sm tw-font-normal tw-text-black satoshi">
                    {it?.title}
                  </small>
                </ScoreCard>
                <ScoreCard>
                  <small className="tw-text-sm tw-font-normal tw-text-black satoshi">
                    {it?.score}
                  </small>
                </ScoreCard>
                {it?.point && (
                  <ScoreCard>
                    <small className="tw-text-sm tw-font-normal tw-text-black satoshi">
                      {it?.point} Point
                    </small>
                  </ScoreCard>
                )}
              </div>
            ))}
            <h6 className="tw-text-base tw-font-medium satoshi tw-text-black">
              You Performed{" "}
              {percent && Number(percent).toFixed(0) >= 60
                ? "Excellently"
                : percent && Number(percent).toFixed(0) >= 50
                ? "Averagely"
                : "Poorly"}
            </h6>
            <TabBtn
              text={"Back"}
              onClick={() => navigate("/")}
              bg={"#1C466C"}
              color={"white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ScoreCard = ({ children }) => {
  return (
    <div
      style={{
        boxShadow: "0px 0px 6.699999809265137px 0px #00000026",
      }}
      className="lg:tw-h-14 tw-h-12 tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-w-full tw-bg-white"
    >
      {children}
    </div>
  );
};

export const QuizBtn = ({ onClick, text, ...restProps }) => {
  return (
    <div>
      <button
        disabled={restProps?.disabled || restProps?.loading}
        onClick={onClick}
        className={`tw-h-12 tw-flex tw-justify-center tw-cursor-pointer tw-items-center tw-w-56 ${
          (restProps?.disabled || restProps?.loading) &&
          "tw-cursor-not-allowed tw-bg-opacity-40"
        } ${
          restProps?.css ||
          "tw-bg-white tw-text-scblue tw-rounded-tr-3xl tw-rounded-bl-3xl satoshi tw-text-xl tw-font-normal"
        }`}
      >
        {text}
        {restProps?.loading && (
          <ClipLoader color={restProps?.loadCss || "white"} size={16} />
        )}
      </button>
    </div>
  );
};
export default Trivia;
