import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import Avatar from "../../assets/avatar.svg";
import { ContactSection, Footer } from "../home";
import useAuthStore, { apiCall } from "../../data/Stores/AuthStore";
import { useNavigate } from "react-router-dom";
import useSettingStore from "../../data/Stores/settingsstore";

const Leaderboard = () => {
	let navigate = useNavigate(),
		{ isAuth } = useAuthStore();

	useEffect(() => {
		if (!isAuth) navigate(-1);
	}, [isAuth, navigate]);

	return (
		<div>
			<HeroSection />
			<MainSection />
			<ContactSection />
			<Footer />
		</div>
	);
};

const HeroSection = () => {
	return (
		<section className="tw-w-full tw-bg-white lg:tw-h-screen tw-h-[70vh] leaderboardbg">
			<Header />
			<div className="container tw-relative tw-h-full">
				<div className="tw-absolute tw-rounded-3xl tw-bg-[#0C1F2E] lg:tw-h-40 tw-py-5 tw-px-6 tw-flex tw-justify-center tw-items-center tw-left-0 tw-bottom-8">
					<div>
						<h1 className="tw-text-white satoshi lg:tw-text-6xl tw-text-2xl tw-font-medium">
							LeaderBoard
						</h1>
					</div>
				</div>
			</div>
		</section>
	);
};

const MainSection = () => {
	const headers = ["SN.", "Name", "Score", "Position"],
		{ getLeaderboard, leaderboard } = useSettingStore(),
		{ user } = useAuthStore();

	useEffect(() => {
		apiCall("get", "/api/v1/question/leaderboard", null, getLeaderboard);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="tw-w-full tw-bg-white tw-py-20">
			<div className="container">
				<h2 className="tw-text-2xl tw-font-bold satoshi tw-text-black">
					Leaderboard
				</h2>
				<div className="tw-w-full tw-border-0.5 tw-border-[#00555A80] tw-rounded-xl tw-p-10">
					<div className="tw-hidden lg:tw-block">
						<table className="tw-w-full">
							<thead>
								{headers?.map((it, i) => (
									<th
										className={`tw-text-sm tw-font-medium satoshi tw-text-[#06094F] ${
											i === 0 ? "tw-w-8" : "tw-w-20"
										}`}>
										{it}
									</th>
								))}
							</thead>
							<tbody>
								{leaderboard?.map((item, i) => (
									<tr className={`tw-border-b tw-border-b-[#CDDCE0] tw-h-16`}>
										<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
											{i + 1}
										</td>
										<td>
											<div className="tw-flex tw-gap-2 tw-items-center">
												<img src={Avatar} alt="" className="" />
												<div>
													<small className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
														{item?.user?.firstName} {item?.user?.lastName}{" "}
														{item?.user?._id === user?._id ? <>(You)</> : null}
													</small>
												</div>
											</div>
										</td>
										<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
											{item?.data}
										</td>
										<td className="tw-text-sm tw-text-[#06094F] tw-font-normal nexa">
											<RankManager score={i} />
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="tw-block lg:tw-hidden">
						<div className="tw-space-y-4">
							{leaderboard?.map((it, i) => (
								<div
									key={i}
									className={`bordered tw-h-20 tw-px-4 tw-w-full tw-flex tw-justify-between tw-items-center`}>
									<div>
										<img src={Avatar} alt="" className="" />
										<div className="tw-mt-2">
											<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
												{it?.user?.firstName} {it?.user?.lastName}{" "}
												{it?.user?._id === user?._id ? <>(You)</> : null}
											</small>
										</div>
									</div>
									<div>
										<div className="tw-mt-22">
											{" "}
											<small className="tw-text-sm tw-font-normal tw-text-plain nexa">
												{it?.data}
											</small>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Leaderboard;

export const RankManager = ({ score }) => {
	let [grader, setGrader] = useState("");

	let hostEndsWith = (host, ends) => {
		let value = false;
		value = ends?.some(element => {
			return host?.toString()?.endsWith(element?.toString());
		});
		return value;
	};
	useEffect(() => {
		if (score) {
			let finder = score + 1,
				finder2 = finder?.toString();
			if (hostEndsWith(finder2, ["11", "12", "13"])) {
				setGrader("th");
			} else if (finder2?.endsWith("1")) setGrader("st");
			else if (finder2?.endsWith("2")) setGrader("nd");
			else if (finder2?.endsWith("3")) setGrader("rd");
			else setGrader("th");
		} else if (score === 0) setGrader("st");
	}, [score]);

	return (
		<>
			{score + 1}
			{grader}
		</>
	);
};
