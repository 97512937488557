import React, { useState } from "react";
import Header from "../../components/header";
import { Footer } from "../home";
import { PurifiedText } from "../terms";

const Contact = () => {
	return (
		<div>
			<HeroSection />
			<MainSection />
			<Footer />
		</div>
	);
};

const HeroSection = () => {
	return (
		<section className="tw-w-full tw-bg-white lg:tw-h-screen tw-h-[70vh] contactbg">
			<Header />
			<div className="container tw-relative tw-h-full">
				<div className="tw-absolute tw-rounded-3xl tw-bg-[#0C1F2E] lg:tw-h-40 tw-py-5 tw-px-6 tw-flex tw-justify-center tw-items-center tw-left-0 tw-bottom-8">
					<div>
						<h1 className="tw-text-white satoshi lg:tw-text-6xl tw-text-2xl tw-font-medium">
							Contact Us
						</h1>
					</div>
				</div>
			</div>
		</section>
	);
};

const MainSection = () => {
	let [type, setType] = useState("");

	return (
		<section className="tw-w-full tw-bg-white tw-py-20">
			<div className="container tw-flex lg:tw-flex-row tw-flex-col tw-justify-between">
				<div className="">
					<h1 className="tw-text-5xl tw-font-bold satoshi tw-text-black">
						Contact Us
					</h1>
					<h5 className="tw-text-2xl tw-font-bold tw-text-[#272424] satoshi">
						Our Office
					</h5>
					<div className="tw-space-y-3">
						<PurifiedText
							type={type}
							setType={setType}
							section={"knowledgeCheck"}
						/>
						{!type && (
							<p className="tw-text-base tw-leading-[37px] tw-font-normal satoshi tw-text-[#272424]">
								Obafemi Awolowo Way, <br />
								Ikeja <br />
								Lagos State, <br />
								Nigeria <br /> Email: info@lagosstate.gov.ng
							</p>
						)}
					</div>
				</div>
				<div className="tw-border-0.5 tw-border-black tw-rounded-xl tw-max-w-2xl tw-bg-white tw-p-8">
					<h3 className="tw-text-2xl tw-font-bold tw-text-black satoshi">
						Get In Touch
					</h3>
					<form action="" className="tw-mt-6">
						<div className="tw-space-y-3">
							<Textinput label={"Name"} />
							<Textinput label={"Email Address"} />
							<Textinput label={"Phone Number"} />
							<div>
								<div>
									<small className="tw-text-base tw-font-normal satoshi tw-text-[#272424]">
										Message
									</small>
								</div>
								<textarea
									name=""
									className="lg:tw-w-96 tw-w-full tw-pl-6 tw-h-28 tw-rounded-lg tw-bg-white tw-text-base tw-font-medium satoshi tw-text-black tw-border-[0.5px] tw-border-[#0000004D]"
									id=""
									cols="30"
									rows="10"></textarea>
							</div>
						</div>
						<div className="tw-mt-8">
							<button className="tw-w-full tw-rounded-lg tw-h-12 tw-bg-black tw-text-white tw-font-medium satoshi tw-text-base">
								Send
							</button>
						</div>
					</form>
				</div>
			</div>
		</section>
	);
};

export const Textinput = ({
	label,
	type,
	name,
	value,
	onChange,
	...restProps
}) => {
	return (
		<div>
			<div>
				<div>
					<small className="tw-text-base tw-font-normal satoshi tw-text-[#272424]">
						{label}
					</small>
				</div>
				<input
					type={type || "text"}
					className="lg:tw-w-96 tw-w-full tw-pl-6 tw-h-10 tw-rounded-lg tw-bg-white tw-text-base tw-font-medium satoshi tw-text-black tw-border-[0.5px] tw-border-[#0000004D]"
					onChange={onChange}
					value={value}
					name={name}
					{...restProps}
				/>
			</div>
		</div>
	);
};

export const NewTextinput = ({
	label,
	type,
	name,
	value,
	onChange,
	...restProps
}) => {
	return (
		<div>
			<div>
				<div>
					<small className="tw-text-base tw-font-normal satoshi tw-text-[#272424]">
						{label}
					</small>
				</div>
				<input
					type={type || "text"}
					className="tw-w-full tw-pl-6 tw-h-14 tw-rounded-lg tw-bg-[#F3F5F5] tw-text-base tw-font-medium satoshi tw-text-black tw-border-[0.5px] tw-border-[#FFFFFF33]"
					onChange={onChange}
					value={value}
					name={name}
					{...restProps}
				/>
			</div>
		</div>
	);
};

export default Contact;
