import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const SettingStore = set => ({
	data: null,
	status: "",
	leaderboard: null,
	getSetting: payload => {
		set({ data: payload?.data || payload }, false, "getSetting");
	},
	getLeaderboard: payload => {
		set({ leaderboard: payload?.data || payload }, false, "getLeaderboard");
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutSetting: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const useSettingStore = create(
	devtools(
		persist(SettingStore, {
			name: "settings",
		})
	)
);

export default useSettingStore;
