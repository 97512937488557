import React, { useContext, useEffect } from "react";
import Header from "../../components/header";
import { ContactSection, Footer } from "../home";
import usePlanStore from "../../data/Stores/planstore";
import { GlobalState } from "../../data/Context";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import { apiCall } from "../../data/Stores/AuthStore";
export const createMarkup = html => {
	return {
		__html: DOMPurify.sanitize(html),
	};
};

const Pricing = () => {
	return (
		<div>
			<HeroSection />
			<MainSection />
			<ContactSection />
			<Footer />
		</div>
	);
};

const HeroSection = () => {
	return (
		<section className="tw-w-full tw-bg-white lg:tw-h-screen tw-h-[70vh] leaderboardbg">
			<Header />
			<div className="container tw-relative tw-h-full">
				<div className="tw-absolute tw-rounded-3xl tw-bg-[#0C1F2E] lg:tw-h-40 tw-py-5 tw-px-6 tw-flex tw-justify-center tw-items-center tw-left-0 tw-bottom-8">
					<div>
						<h1 className="tw-text-white satoshi lg:tw-text-6xl tw-text-2xl tw-font-medium">
							Subscription Plan
						</h1>
					</div>
				</div>
			</div>
		</section>
	);
};
const MainSection = () => {
	const pricings = [
		{
			color: "#00B612",
			plan: "Bronze",
			features: [
				"One day free trial",
				"100/day",
				"24 Hours access",
				"full access to all",
				"features",
				"70 bonus coins",
				"eligible to win cash prizes",
			],
		},
		{
			color: "#3300FF",
			plan: "Silver",
			features: [
				"One day free trial",
				"100/day",
				"24 Hours access",
				"full access to all",
				"features",
				"70 bonus coins",
				"eligible to win cash prizes",
			],
		},
		{
			color: "#B60042",
			plan: "Gold",
			features: [
				"One day free trial",
				"100/day",
				"24 Hours access",
				"full access to all",
				"features",
				"70 bonus coins",
				"eligible to win cash prizes",
			],
		},
		{
			color: "#7CB600",
			plan: "Ultimate",
			features: [
				"One day free trial",
				"100/day",
				"24 Hours access",
				"full access to all",
				"features",
				"70 bonus coins",
				"eligible to win cash prizes",
			],
		},
	];
	const { data, getPlan } = usePlanStore(),
		{ nairaSign, numberWithCommas } = useContext(GlobalState),
		navigate = useNavigate();

	useEffect(() => {
		apiCall("get", "/api/v1/plan", null, getPlan);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<section className="tw-w-full tw-py-20 tw-bg-white">
			<div className="container tw-border-0.5 tw-border-[#00555A80] tw-rounded-xl tw-p-10">
				<h2 className="tw-text-2xl tw-text-black satoshi tw-font-bold tw-text-center">
					Choose a Plan that's right for you.
				</h2>
				<div className="tw-w-full tw-px-8 tw-mt-10 tw-grid lg:tw-grid-cols-4 md:tw-grid-cols-2 tw-gap-6">
					{data?.docs?.map((it, i) => (
						<div
							key={i}
							style={{
								borderColor: pricings?.[i % (pricings?.length - 1)]?.color,
							}}
							className={`tw-w-full tw-mb-10 lg:tw-mb-0 tw-h-[500px] tw-relative tw-rounded-2xl tw-border`}>
							<h4
								style={{
									borderBottomColor:
										pricings?.[i % (pricings?.length - 1)]?.color,
								}}
								className="tw-text-plain tw-font-bold tw-text-2xl tw-pl-4 tw-border-b tw-py-5 satoshi tw-capitalize">
								{it?.title} Plan
							</h4>
							<div className="tw-mt-6 tw-px-4">
								<ul className="tw-space-y-4 tw-list-disc">
									<li className="tw-text-base tw-capitalize tw-font-normal satoshi tw-text-black">
										{nairaSign}{" "}
										{numberWithCommas(Number(it?.amount).toFixed(2))}/
										{it?.perAmount}
									</li>
									<li className="tw-text-base tw-capitalize tw-font-normal satoshi tw-text-black">
										{it?.lengthOfFrequency} {it?.frequency} Access
									</li>
									<li className="tw-text-base tw-capitalize tw-font-normal satoshi tw-text-black">
										{numberWithCommas(it?.noOfCoin)} Bonus Coins
									</li>
									{/* <li className="tw-text-base tw-capitalize tw-font-normal satoshi tw-text-black">
									</li> */}
									{/* {it?.features?.map((feature, index) => (
                    <li
                    key={index}
                    className="tw-text-base tw-capitalize tw-font-normal satoshi tw-text-black">
                    {feature}
										</li>
									))} */}
								</ul>
							</div>
							<div
								className="tw-text-base tw-capitalize tw-font-normal satoshi tw-text-black"
								dangerouslySetInnerHTML={createMarkup(it?.features)}
							/>
							<div className="tw-absolute tw-bottom-4 tw-w-full  tw-left-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-px-4">
								<button
									style={{
										boxShadow: "0px 6px 15.5px 0px #00000040",
									}}
									className="tw-h-12 tw-w-full tw-text-base tw-font-bold tw-text-white satoshi tw-rounded-xl tw-bg-scblue"
									onClick={
										it?.planId === "yearly"
											? () => {}
											: () => {
													navigate(it?.title, {
														state: {
															amount: it?.amount,
															currency: "naira",
															paymentType:
																it?.amount > 1000 ? "not-airtime" : "",
															item: it,
														},
													});
											  }
									}>
									Subscribe
								</button>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Pricing;
