import React from "react";
import { IoClose } from "react-icons/io5";
import "./loading.css";

const ModalContainer = ({ handleClose, children, height }) => {
	return (
		<div>
			<div
				onClick={e => e.target === e.currentTarget && handleClose()}
				className="tw-fixed tw-inset-0 tw-z-[1000] tw-w-full tw-flex tw-justify-center tw-items-center tw-min-h-screen tw-bg-primary tw-bg-opacity-50 tw-backdrop-blur-sm">
				<div
					className={`tw-w-full tw-max-w-md  tw-bg-white tw-rounded-lg tw-shadow-sm tw-transition-all tw-ease-in-out tw-duration-700 ${
						height && "tw-h-3/4"
					} tw-overflow-y-scroll noScroll p-4`}>
					{" "}
					<div className="tw-flex tw-justify-end">
						<IoClose className="tw-cursor-pointer" onClick={handleClose} />
					</div>
					{children}
				</div>
			</div>
		</div>
	);
};

export default ModalContainer;

export const LoadingLoader = () => {
	return (
		<div className="loader">
			<div className="hexagon" aria-label="Animated hexagonal ripples">
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
				<div className="hexagon__group">
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
					<div className="hexagon__sector"></div>
				</div>
			</div>
			<div className="tw-flex tw-items-center tw-justify-center">
				<p aria-label="Loading" className="text-center">
					Loading
				</p>
			</div>
		</div>
	);
};