import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import Routers from "./Routes";
import { SetAuthToken, SetDefaultHeaders } from "./data/Config";
import { TOKEN } from "./data/Stores/AuthStore";
import useGenFetcher from "./data/useGenFetcher";
import { useEffect } from "react";
import DataProvider from "./data/Context";

SetDefaultHeaders();

if (localStorage.getItem(TOKEN)) {
	SetAuthToken(localStorage.getItem(TOKEN));
}

const App = () => {
	let { loadUser } = useGenFetcher();

	useEffect(() => {
		loadUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<DataProvider>
			<Router>
				<Routers />
			</Router>
		</DataProvider>
	);
};

export default App;
