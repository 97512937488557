import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";

const PlanStore = set => ({
	data: null,
	status: "",
	getPlan: payload => {
		set({ data: payload?.data || payload }, false, "getPlan");
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutPlan: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null });
	},
});

const usePlanStore = create(
	devtools(
		persist(PlanStore, {
			name: "plan",
		})
	)
);

export default usePlanStore;
